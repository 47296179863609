
import ('./bootstrap.bundle.min.js');

const showStuff = (show ,hide) => {
  show.forEach(element => {
    const target =document.getElementsByClassName(element)[0]
    console.log('target',target[0])
    if(element.includes('btn')){
      target.classList.remove('inactive')
      target.classList.add('active')
    } else {
      target.classList.remove('hide')
    }
  });
  hide.forEach(element => {
    const target =document.getElementsByClassName(element)[0]
    if(element.includes('btn')){
      target.classList.remove('active')
      target.classList.add('inactive')
    } else {
      target.classList.add('hide')
    }
  });
}
// event listener
window.addEventListener('load', () => {
  console.log('load')
  setTimeout(()=>{
    document.querySelector('.preload-bg').classList.add('ra-hidden')
    setTimeout(()=>{
      document.querySelector('.preload-bg').parentNode.removeChild(document.querySelector('.preload-bg'));
    },  500)
    document.querySelector('body').classList.remove('ra-loaded');
    document.getElementById('ra-year').innerHTML = new Date().getFullYear();
    setTimeout(()=>{
      document.querySelector('body').classList.remove('ra-preload'); 
    }, 1500)
  }, 800)
})
window.addEventListener('scroll', () => {
  // console.log('scroll')
  let scrollTop = window.pageYOffset;
  let navElement = document.querySelector('nav');
  let isBgColor = navElement.classList.contains('ra-nav-bg')
  if(scrollTop > 10 && !isBgColor){
    navElement.classList.add('ra-nav-bg');
  }else if(scrollTop == 0 && isBgColor){
    navElement.classList.remove('ra-nav-bg');
  }
})
document.getElementById('navBtn').addEventListener('click', (e) => {
  // console.log('click #navbarNav ul')
  const menu = document.getElementById('navMenu');
  console.log('navBtn',menu )
  if(menu.classList.contains('show')){
    menu.classList.remove('show')
  }else{
    menu.classList.add('show')
  }
})
document.querySelector('#navMenu ul').addEventListener('click', (e) => {
  console.log('click #navbarNav ul')
  const menu = document.getElementById('navMenu');
  if(window.innerWidth <= 767.98 && menu.classList.contains('show')){
    document.getElementById('navMenu').classList.remove('show')
  }
})
document.getElementById('interview').addEventListener('click', (e) => {
  showStuff(
    ['btn-interview', 'page-interview', 'pic-interview'],
    ['btn-job', 'page-job', 'pic-job'],
  )
})
document.getElementById('job').addEventListener('click', (e) => {
  showStuff(
    ['btn-job', 'page-job', 'pic-job'],
    ['btn-interview', 'page-interview', 'pic-interview'],
  )

})



